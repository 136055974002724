<script>
  import ClientDataService from "@/services/ClientDataService";
  export default {
    props: ["label", "model", "placeholder", "client"],
    data() {
      return {
        entry: "",
      };
    },
    methods: {
      updateDetailInput() {
        console.log("engID ", this.client.engID);
        ClientDataService.updateDetailInput(this.client.client, this.client.engID, this.model, this.entry)
          .then(() => {
            console.log("Updated detail input item");
          })
          .catch((e) => {
            console.log(e);
          });
        this.$emit("update-entry", this.model, this.entry);
      },
    },
  };
</script>

<template>
  <div class="pr-3 mb-2 ml-3 md:pr-20">
    <label for="email" class="block ml-2 text-sm font-medium text-gray-400">{{ label }}</label>
    <div class="flex mt-0 rounded-md shadow-sm">
      <div class="relative flex items-stretch flex-grow focus-within:z-10">
        <input
          type="text"
          :name="model"
          :id="model"
          v-model="entry"
          class="block w-full pl-3 border-gray-300 rounded-none focus:ring-blue-500 focus:border-blue-500 rounded-l-md sm:text-sm"
          :placeholder="placeholder"
        />
      </div>
      <button
        type="button"
        class="relative inline-flex items-center px-4 py-2 -ml-px space-x-2 text-sm font-medium text-gray-700 border border-gray-300 rounded-r-md bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
        @click="updateDetailInput()"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="w-6 h-6 text-gray-400"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
        </svg>
      </button>
    </div>
  </div>
</template>

<style></style>
