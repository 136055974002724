<script>
	import ClientDataService from "@/services/ClientDataService";
	import CompanyDetails from "@/components/CompanyDetails.vue";
	import repgenSections from "@/data/RepgenSections";
	import Sections from "@/components/repgen/RepgenSections.vue";
	import SwotSection from "@/components/swot/SwotSection.vue";
	import ClientDetailInput from "@/components/UI/ClientDetailInput.vue";
	import Cubegen from "@/views/Cubegen.vue"
	import { repgen } from "@/firebase";
	import { collection, onSnapshot } from "@firebase/firestore";
	// import ProgressBar from "@/components/UI/ProgressBar.vue";
	export default {
		components: {
			CompanyDetails,
			Sections,
			SwotSection,
			ClientDetailInput,
			Cubegen,
		},
		data() {
			return {
				client: null,
				repgenSections: repgenSections,
				questionNum: 1,
				userName: "",
				swotSections: [
					{
						title: "PARKING LOT",
						collection: "parking_lot",
					},
					{
						title: "EXEC SUMMARY",
						collection: "exec_summary",
					},
					{
						title: "STRENGTHS",
						collection: "strengths",
					},
					{
						title: "WEAKNESSES",
						collection: "weaknesses",
					},
					{
						title: "OPPORTUNITIES",
						collection: "opportunities",
					},
					{
						title: "THREATS",
						collection: "threats",
					},
					{
						title: "RECOMMENDATIONS",
						collection: "recommendations",
					},
					{
						title: "INFO",
						collection: "info",
					},
					{
						title: "QUOTES",
						collection: "quotes",
					},
					{
						title: "ADD",
						collection: "adds",
					},
				],
				cube: {
					x: 9,
					y: 9,
					z: 9
				}
			};
		},
		created() {
			console.log(this.$route.params.clientID);
			this.getClient(this.$route.params.clientName, this.$route.params.engID).then(() => {
				this.getScore('Scalability X Axis', 'x')
				this.getScore('Scalability Y Axis', 'y')
				this.getScore('Scalability Z Axis', 'z')
			});
		},
		mounted() {
			this.$httpMS
				.get("/me/")
				.then((response) => {
					console.log(response.data);
					this.userName = response.data.givenName;
				})
				.catch((err) => {
					console.log(err);
				});
		},
		methods: {
			async getClient(clientName, engID) {
				if(engID) {
					let snapshot = await ClientDataService.getEngagement(clientName, engID)
					this.client = snapshot.data();
				} else {
					let snapshot = await ClientDataService.getOneClient(clientName)
					snapshot.forEach((doc) => {
						this.client = doc.data();
					});
				}
				console.log(this.client);
			},
			getTab() {
				return this.$route.params.tab || ""
			},
			updateEntry(model, entry) {
				if (model == "slackChannelName") {
					this.client.slackChannelName = entry;
				}
			},
			getScore(section, axis) {
				console.log(`Scoring ${axis} axis`)
				onSnapshot(collection(repgen, "clients", this.client.repgenOverride || this.client.client, section), {includeMetadataChanges: true}, (querySnapshot) => {
					let points = 0;
					let possiblePoints = 0;
					querySnapshot.forEach((doc) => {
						var question = doc.data();
						if(question.recommended) {
							points += +question.likert
							possiblePoints += 4
						}
					});
					console.log(`Score for ${section} is ${points} / ${possiblePoints}`)
					if(possiblePoints == 0) this.cube[axis] = 0
					else this.cube[axis] = +(points/possiblePoints * 10).toFixed(1)
					console.log(`Cube is now (${this.cube.x}, ${this.cube.y}, ${this.cube.z})`)
				})
			}
		}
	};
</script>

<template>
	<div class="px-6 py-6" v-if="client">
		<div class="flex justify-between">
			<h1 class="ml-6 text-6xl font-semibold text-blue-700 dark:text-blue-500">
				<span class="uppercase">{{ client.engType }}</span
				>-{{ client.client }}
			</h1>
		</div>

		<div class="pb-10 bg-white rounded">
			<div class="px-12 mt-8 mb-8">
				<div class="sm:hidden">
					<label for="tabs" class="sr-only">Select a tab</label>
					<!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
					<select
						id="tabs"
						name="tabs"
						class="block w-full border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
					>
						<option>Details</option>

						<option selected>RepGen</option>

						<option>SWOT</option>
					</select>
				</div>
				<div class="hidden sm:block">
					<div class="border-b border-gray-200">
						<nav class="flex -mb-px space-x-8" aria-label="Tabs">
							<router-link
								:to="{ name: 'clientSingle', params: { clientName: client.client, engID: client.engID }}"
								class="inline-flex items-center px-1 py-4 text-sm font-medium border-b-2 group"
								:class="getTab() == '' ? 'text-blue-600 border-blue-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'"
							>
								<!-- Heroicon name: solid/office-building -->
								<svg
									class="-ml-0.5 mr-2 h-5 w-5"
									:class="getTab() == '' ? 'text-blue-600 group-hover:text-blue-500' : 'text-gray-400 group-hover:text-gray-500'"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 20 20"
									fill="currentColor"
									aria-hidden="true"
								>
									<path
										fill-rule="evenodd"
										d="M4 4a2 2 0 012-2h8a2 2 0 012 2v12a1 1 0 110 2h-3a1 1 0 01-1-1v-2a1 1 0 00-1-1H9a1 1 0 00-1 1v2a1 1 0 01-1 1H4a1 1 0 110-2V4zm3 1h2v2H7V5zm2 4H7v2h2V9zm2-4h2v2h-2V5zm2 4h-2v2h2V9z"
										clip-rule="evenodd"
									/>
								</svg>
								<span>Engagement Details</span>
							</router-link>

							<router-link
								:to="{ name: 'clientSingle', params: { clientName: client.client, engID: client.engID, tab: 'repgen' }}"
								class="inline-flex items-center px-1 py-4 text-sm font-medium border-b-2 group"
								:class="getTab() == 'repgen' ? 'text-blue-600 border-blue-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'"
								aria-current="page"
							>
								<!-- Heroicon name: solid/users -->
								<svg
									class="-ml-0.5 mr-2 h-5 w-5"
									:class="getTab() == 'repgen' ? 'text-blue-600 group-hover:text-blue-500' : 'text-gray-400 group-hover:text-gray-500'"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 24 24"
									stroke="currentColor"
									fill="none"
									aria-hidden="true"
								>
									<path
										stroke-linecap="round"
										stroke-linejoin="round"
										stroke-width="2"
										d="M20 7l-8-4-8 4m16 0l-8 4m8-4v10l-8 4m0-10L4 7m8 4v10M4 7v10l8 4"
									/>
								</svg>

								<span>RepGen</span>
							</router-link>

							<router-link
								:to="{ name: 'clientSingle', params: { clientName: client.client, engID: client.engID, tab: 'swot' }}"
								class="inline-flex items-center px-1 py-4 text-sm font-medium text-gray-500 border-b-2"
								:class="getTab() == 'swot' ? 'text-blue-600 border-blue-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'"
							>
								<!-- Heroicon name: solid/credit-card -->
								<svg
									class="-ml-0.5 mr-2 h-5 w-5"
									:class="getTab() == 'swot' ? 'text-blue-600 group-hover:text-blue-500' : 'text-gray-400 group-hover:text-gray-500'"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 24 24"
									stroke="currentColor"
									fill="none"
									aria-hidden="true"
								>
									<path
										stroke-linecap="round"
										stroke-linejoin="round"
										stroke-width="2"
										d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
									/>
								</svg>
								<span>SWOT</span>
							</router-link>

							<router-link
								:to="{ name: 'clientSingle', params: { clientName: client.client, engID: client.engID, tab: 'cube' }}"
								class="inline-flex items-center px-1 py-4 text-sm font-medium border-b-2 group"
								:class="getTab() == 'cube' ? 'text-blue-600 border-blue-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'"
								aria-current="page"
							>
								<!-- Heroicon name: solid/users -->
								<svg
									class="-ml-0.5 mr-2 h-5 w-5"
									:class="getTab() == 'cube' ? 'text-blue-600 group-hover:text-blue-500' : 'text-gray-400 group-hover:text-gray-500'"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 24 24"
									stroke="currentColor"
									fill="none"
									aria-hidden="true"
								>
									<path
										stroke-linecap="round"
										stroke-linejoin="round"
										stroke-width="2"
										d="M20 7l-8-4-8 4m16 0l-8 4m8-4v10l-8 4m0-10L4 7m8 4v10M4 7v10l8 4"
									/>
								</svg>

								<span>Cube</span>
							</router-link>
						</nav>
					</div>
				</div>
			</div>
			<div class="px-4 text-gray-500 md:px-12" v-if="getTab()==''">
				<!-- <div class="mb-10 ">
          <p class="mb-2 text-lg font-semibold">Progress of Phase {{ client.phase }}:</p>
          <ProgressBar :progress="client.progress" />
        </div> -->
				<div class="px-0 md:px-10">
					<CompanyDetails :client="client" />
				</div>
			</div>
			<div class="m-3 mt-6" v-if="getTab()=='repgen'">
				<div class="flex items-end text-gray-600 border-b border-gray-300 dark:text-gray-400">
					<div class="w-6"></div>
					<div class="w-1/2 py-2 pr-4 text-center"></div>
					<div class="w-24 py-1 pl-16">Likert</div>
					<div class="w-16 py-1 pl-12">Reco</div>
					<div class="flex-1 py-1 pl-10">Comments</div>
				</div>

				<Sections
					v-for="(repgenSection, index) in repgenSections"
					:key="index"
					:section="repgenSection.title"
					:client="client.repgenOverride || client.client"
					:questionNum="questionNum"
					:userName="userName"
				/>
			</div>
			<div v-if="getTab()=='swot' && client.slackChannelName" class="m-3 mt-6">
				<SwotSection
					v-for="section in swotSections"
					:key="section.collection"
					:title="section.title"
					:collection="section.collection"
					:clientId="client.slackChannelName"
				/>
			</div>
			<div v-if="getTab()=='swot' && !client.slackChannelName" class="text-red-400">
				There is no SWOT linked to this client.
				<ClientDetailInput
					model="slackChannelName"
					:client="client"
					label="Slack Channel Name"
					placeholder="Enter slack channel name"
					v-model="client.slackChannelName"
					@update-entry="updateEntry"
				/>
			</div>
			<div v-if="getTab() == 'cube'">
				<Cubegen
					:inputX="cube.x"
					:inputY="cube.y"
					:inputZ="cube.z" />
			</div>
		</div>
	</div>
	<!-- <pre>
      {{ clients }}
  </pre> -->
</template>

<style></style>
