<script>
	import ClientDataService from "@/services/ClientDataService";
	import EmployeeService from "@/services/EmployeeService";
	import Datepicker from "vue3-datepicker";

	import EditEntry from "@/components/UI/EditEntry.vue";
	import ClientDetailInput from "./UI/ClientDetailInput.vue";

	export default {
		props: ["client"],
		components: {
			EditEntry,

			ClientDetailInput,
			Datepicker,
		},
		data() {
			return {
				targetRevenue: this.client.targetRevenue,
				numEmployees: this.client.numEmployees,
				numQA: this.client.numQA,
				numPM: this.client.numPM,
				yearsInBusiness: this.client.yearsInBusiness,
				industry: null,
				phase: "2",
				active: true,
				progress: "45",
				sfLink: this.client.sfLink,
				spLink: this.client.spLink,
				contractLink: this.client.contractLink,
				sowLink: "",
				cap: null,
				contact: {
					name: "Steve Schroder",
					email: "steve@steve.com",
				},
				engDate: null,
				deliveryDate: this.client.deliveryDate,
				delDate: null,
				editDate: false,
				engStartDate: this.client.engStartDate,
				dateFormat: "dd-MM-YYYY",
				engEndDate: "12/03/21",
				notes: "Capped at $70k",
				employeeList: {}
			};
		},
		mounted() {
			EmployeeService.getEmployeeList().then(employeeList => this.employeeList = employeeList)
		},
		methods: {
			recordDate(model, newDate) {
				console.log("yo date");
				ClientDataService.updateDate(this.client.client, this.client.engID, model, newDate);
			},
			updateChecklist(checklistID, ID) {
				console.log("UPDATE engID ", this.client.engID);

				ClientDataService.updateChecklist(
					this.client.client,
					this.client.engID,
					this.client.checklists,
					checklistID,
					ID
				)
					.then(() => {
						console.log("Updated checklist item");
					})
					.catch((e) => {
						console.log(e);
					});
			},
			updateEntry(model, entry) {
				console.log("emit", model, entry);
				if (model == "sfLink") {
					this.sfLink = entry;
				} else if (model == "spLink") {
					this.spLink = entry;
				} else if (model == "targetRevenue") {
					this.targetRevenue = entry;
				} else if (model == "numEmployees") {
					this.numEmployees = entry;
				} else if (model == "numQA") {
					this.numQA = entry;
				} else if (model == "numPM") {
					this.numPM = entry;
				} else if (model == "yearsInBusiness") {
					this.yearsInBusiness = entry;
				} else if (model == "engStartDate") {
					this.engStartDate = entry;
				}
			},
			saveEngStartDate(engDate) {
				this.engStartDate = engDate.toDate().toDateString();
				this.updateEntry("engStartDate", this.engStartDate);
			},
			nextPhase() {
				ClientDataService.updateDetailInput(this.client.client, this.client.engID, "phase", this.client.phase + 1)
					.then(() => {
						console.log("Updated phase");
						this.$router.push("/")
					})
					.catch((e) => {
						console.log(e);
					});
			},
		},
		computed: {
			displayDelDate() {
				return this.delDate;
			},
		},
		watch: {
			engDate: function() {
				this.recordDate("engStartDate", this.engDate);
			},
			delDate: function() {
				this.recordDate("deliveryDate", this.delDate);
			},
		},
	};
</script>

<template>
	<button
		@click="nextPhase()"
		class="flex items-center px-3 py-1 mb-8 mr-20 text-sm text-white bg-blue-600 rounded-md hover:bg-blue-700"
	>
		Next Phase
		<svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
			<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 5l7 7-7 7M5 5l7 7-7 7" />
		</svg>
	</button>
	<div class="flex flex-wrap">
		<div class="w-full md:w-1/2">
			<div class="mb-6 ">
				<h2 class="mb-1 text-lg font-semibold">Important Dates:</h2>
				<div class="ml-3">
					<p class="block text-sm font-medium text-gray-400">Engagement Date:</p>
					<div v-if="engDate || engStartDate">
						<span v-if="engDate" class="flex items-center justify-between pr-12 text-xl font-semibold text-blue-600">
							{{ engDate.toDateString().slice(0, 15) }}
						</span>
						<span v-else class="flex items-center justify-between pr-12 text-lg font-semibold text-blue-600">
							<span>{{ engStartDate.toDate().toDateString() }}</span> <EditEntry />
						</span>
					</div>
					<div v-else>
						<div
							class="flex w-5/6 pl-3 border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500 sm:text-md"
						>
							<Datepicker
								class="block w-5/6 pl-3 mt-2 border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
								v-model="engDate"
								:enableTimePicker="false"
								autoApply
								placeholder="Select Start Date"
							></Datepicker>
						</div>
					</div>
				</div>
				<div class="mt-3 ml-3">
					<p class="block text-sm font-medium text-gray-400">Report Delivery Date:</p>
					<div v-if="delDate || deliveryDate">
						<span v-if="delDate" class="flex items-center justify-between pr-12 text-xl font-semibold text-blue-600">
							{{ delDate.toDateString().slice(0, 15) }} <EditEntry />
						</span>
						<span v-else class="flex items-center justify-between pr-12 text-lg font-semibold text-blue-600">
							<!-- {{ deliveryDate.toDate().toDateString() }} <EditEntry /> -->
							<span>{{ deliveryDate.toDate().toDateString() }}</span> <EditEntry />
						</span>
					</div>
					<div v-else>
						<div
							class="flex w-5/6 pl-3 border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500 sm:text-md"
						>
							<Datepicker
								class="block w-5/6 pl-3 mt-2 border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
								v-model="delDate"
								:enableTimePicker="false"
								autoApply
								placeholder="Select Delivery Date"
							></Datepicker>
						</div>
					</div>
				</div>
			</div>
			<div class="mb-6 ">
				<p class="mb-0 text-lg font-semibold">AKFers:</p>
				<div class="flex items-center justify-between pr-12 ml-3">
					<div>
						<span v-for="(akfer, index) in client.akfers" :key="index" class="mr-3 text-lg">
							{{ employeeList[akfer]?.nickName }}
						</span>
					</div>
					<EditEntry :client="client" :edit="client.akfers" />
				</div>
			</div>
			<div class="mb-6 ">
				<h2 class="mb-1 text-lg font-semibold">Engagement Details:</h2>
				<span v-if="contractLink" class="flex items-center justify-between pr-12 my-4" :key="client.contractLink">
					<a :href="client.contractLink" class="flex items-center ml-4 text-sm text-blue-500 2" target="_blank">
						Contract Link
						<svg
							xmlns="http://www.w3.org/2000/svg"
							class="w-5 h-5 ml-2"
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor"
						>
							<path
								stroke-linecap="round"
								stroke-linejoin="round"
								stroke-width="2"
								d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
							/>
						</svg>
					</a>
					<EditEntry />
				</span>
				<ClientDetailInput
					v-else
					model="contractLink"
					:client="client"
					label="Contract Link"
					placeholder="Enter contract Link"
					v-model="contractLink"
					@update-entry="updateEntry"
				/>
				<span v-if="sfLink" class="flex items-center justify-between pr-12 my-4" :key="client.sfLink">
					<a :href="client.sfLink" class="flex items-center ml-4 text-sm text-blue-500 2" target="_blank">
						Salesforce Entry
						<svg
							xmlns="http://www.w3.org/2000/svg"
							class="w-5 h-5 ml-2"
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor"
						>
							<path
								stroke-linecap="round"
								stroke-linejoin="round"
								stroke-width="2"
								d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
							/>
						</svg>
					</a>
					<EditEntry />
				</span>
				<ClientDetailInput
					v-else
					model="sfLink"
					:client="client"
					label="Salesforce Link"
					placeholder="Enter SF Link"
					v-model="sfLink"
					@update-entry="updateEntry"
				/>
				<span v-if="spLink" class="flex items-center justify-between pr-12 my-4">
					<a :href="client.spLink" class="flex items-center ml-4 text-sm text-blue-500 2" target="_blank">
						Sharepoint Link
						<svg
							xmlns="http://www.w3.org/2000/svg"
							class="w-5 h-5 ml-2"
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor"
						>
							<path
								stroke-linecap="round"
								stroke-linejoin="round"
								stroke-width="2"
								d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
							/>
						</svg>
					</a>
					<EditEntry />
				</span>
				<ClientDetailInput
					v-else
					model="spLink"
					:client="client"
					label="SharePoint Link"
					placeholder="Enter Sharepoint Link"
					v-model="spLink"
					@update-entry="updateEntry"
				/>
			</div>
			<!-- Add a tab for this later -->
			<!-- <div class="mb-6 ">
				<h2 class="mb-0 mb-1 text-lg font-semibold">Company Details:</h2>
				<div>
					<div v-if="targetRevenue" class="flex items-center justify-between pr-12 my-4">
						<span class="text-gray-400"
							>Target Revenue: <span class="text-gray-600">{{ targetRevenue }}</span> </span
						><EditEntry />
					</div>
					<ClientDetailInput
						v-else
						label="Revenue"
						:client="client"
						placeholder="Enter Revenue"
						model="targetRevenue"
						v-model="targetRevenue"
						@update-entry="updateEntry"
					/>
				</div>
				<div>
					<div v-if="numEmployees" class="flex items-center justify-between pr-12 my-4">
						<span class="text-gray-400"
							>Total Employees: <span class="text-gray-600">{{ numEmployees }}</span> </span
						><EditEntry />
					</div>
					<ClientDetailInput
						v-else
						label="Number of Employees"
						:client="client"
						placeholder="Enter Number of Employees"
						model="numEmployees"
						v-model="numEmployees"
						@update-entry="updateEntry"
					/>
				</div>

				<div>
					<div v-if="numQA" class="flex items-center justify-between pr-12 my-4">
						<span class="text-gray-400"
							>Total QA: <span class="text-gray-600">{{ numQA }}</span> </span
						><EditEntry />
					</div>
					<ClientDetailInput
						v-else
						label="Number of QA"
						:client="client"
						placeholder="Enter Number of QA"
						model="numQA"
						v-model="numQA"
						@update-entry="updateEntry"
					/>
				</div>

				{{ client.numPM }}
				<ClientDetailInput label="Number of PMs" placeholder="Enter Number of PMs" model="numPM" v-model="numPM" />
				{{ client.yearsInBusiness }}
				<ClientDetailInput
					label="Years in Business"
					placeholder="Enter Years in Business"
					model="yearsInBusiness"
					v-model="yearsInBusiness"
				/>
			</div> -->
		</div>
		<!-- Right Column -->
		<div class="w-full md:w-1/2">
			<div class="mb-6 ">
				<h2 class="mb-1 text-lg font-semibold">To Do's:</h2>
				<fieldset class="ml-3 space-y-1">
					<div
						v-for="(item, index) in client.checklists[client.phase].checklist"
						:key="index"
						class="relative flex items-start items-center"
					>
						<div class="flex items-center h-5">
							<input
								id="comments"
								aria-describedby="comments-description"
								name="comments"
								type="checkbox"
								@change="updateChecklist(client.checklists[client.phase], index)"
								v-model="item.done"
								class="w-5 h-5 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
							/>
						</div>
						<div class="ml-3 text-base">
							<label for="comments" class="text-gray-700 ">{{ item.item }}</label>
						</div>
					</div>
					<div class="pt-5 ">
						<div v-for="(item, index) in client.todos" :key="index" class="relative flex items-start items-center ">
							<div class="flex items-center h-5">
								<input
									id="comments"
									aria-describedby="comments-description"
									name="comments"
									type="checkbox"
									v-model="item.done"
									@change="updateChecklist(client.checklists[client.phase], index)"
									class="w-5 h-5 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
								/>
							</div>
							<div class="ml-3 text-base">
								<label for="comments" class="text-gray-700 "
									>{{ item.item }} <span class="ml-2 font-semibold text-blue-500">{{ item.responsible }}</span></label
								>
							</div>
						</div>
					</div>
				</fieldset>
			</div>
			<div>
				<div class="mb-6 ">
					<h2 class="mb-0 text-lg font-semibold">Notes:</h2>
					<p class="ml-3">{{ client.notes }}</p>
				</div>
			</div>
		</div>
	</div>
</template>

<style></style>
